var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-layout",
    {
      attrs: {
        wrap: "",
        "justify-start": "",
        "pa-2": "",
        "pa-sm-4": "",
        "pa-md-8": "",
      },
    },
    [
      _c(
        "v-flex",
        { attrs: { xs12: "" } },
        [
          _c(
            "v-layout",
            { attrs: { wrap: "", "justify-end": "", "pb-4": "" } },
            [
              _c(
                "v-flex",
                { attrs: { xs12: "", sm6: "", lg4: "", xl3: "" } },
                [
                  _c(
                    "v-layout",
                    { attrs: { wrap: "", "justify-end": "" } },
                    [
                      _c(
                        "v-flex",
                        {
                          attrs: {
                            xs6: "",
                            md6: "",
                            "px-2": "",
                            "text-right": "",
                            "align-self-center": "",
                          },
                        },
                        [
                          _c("v-select", {
                            attrs: {
                              placeholder: _vm.$t("form.month"),
                              outlined: "",
                              dense: "",
                              "hide-details": "",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "v-flex",
                        {
                          attrs: {
                            xs6: "",
                            md6: "",
                            "px-2": "",
                            "text-right": "",
                            "align-self-center": "",
                          },
                        },
                        [
                          _c("v-text-field", {
                            attrs: {
                              placeholder: _vm.$t("calendar.date"),
                              type: "search",
                              outlined: "",
                              dense: "",
                              "hide-details": "",
                              "append-icon": "mdi-calendar",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "v-flex",
        { attrs: { xs12: "" } },
        [
          _c(
            "v-layout",
            { attrs: { wrap: "", "justify-start": "" } },
            _vm._l(_vm.reports, function (item, i) {
              return _c(
                "v-flex",
                { key: i, attrs: { xs12: "", "pa-2": "" } },
                [
                  _c(
                    "v-card",
                    {
                      staticClass: "elevated-card",
                      attrs: { outlined: "", rounded: "lg" },
                    },
                    [
                      _c(
                        "v-layout",
                        {
                          attrs: { wrap: "", "justify-start": "", "pa-4": "" },
                        },
                        [
                          _c(
                            "v-flex",
                            { attrs: { xs10: "" } },
                            [
                              _c(
                                "v-layout",
                                { attrs: { wrap: "", "justify-start": "" } },
                                [
                                  _c(
                                    "v-flex",
                                    {
                                      staticClass: "title-1",
                                      attrs: { xs12: "" },
                                    },
                                    [
                                      _vm._v(
                                        " " + _vm._s(_vm.$t(item.title)) + " "
                                      ),
                                    ]
                                  ),
                                  _c(
                                    "v-flex",
                                    {
                                      staticClass: "subtitle-2",
                                      attrs: { xs12: "", "pt-2": "" },
                                    },
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(_vm.$t(item.description)) +
                                          " "
                                      ),
                                    ]
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "v-flex",
                            { attrs: { xs2: "", "text-right": "" } },
                            [
                              _c("AppButton", {
                                attrs: { "custom-class": "pa-5" },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "content",
                                      fn: function () {
                                        return [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                _vm.$t("buttons.download")
                                              ) +
                                              " "
                                          ),
                                        ]
                                      },
                                      proxy: true,
                                    },
                                  ],
                                  null,
                                  true
                                ),
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              )
            }),
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }