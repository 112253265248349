/* This File is for {purpose} */

<template>
  <v-layout wrap justify-start pa-2 pa-sm-4 pa-md-8>
    <v-flex xs12>
      <v-layout wrap justify-end pb-4>
        <v-flex xs12 sm6 lg4 xl3>
          <v-layout wrap justify-end>
            <v-flex xs6 md6 px-2 text-right align-self-center>
              <v-select
                :placeholder="$t('form.month')"
                outlined
                dense
                hide-details
              />
            </v-flex>
            <v-flex xs6 md6 px-2 text-right align-self-center>
              <v-text-field
                :placeholder="$t('calendar.date')"
                type="search"
                outlined
                dense
                hide-details
                append-icon="mdi-calendar"
              />
            </v-flex>
          </v-layout>
        </v-flex>
      </v-layout>
    </v-flex>
    <v-flex xs12>
      <v-layout wrap justify-start>
        <v-flex xs12 v-for="(item, i) in reports" :key="i" pa-2>
          <v-card outlined rounded="lg" class="elevated-card">
            <v-layout wrap justify-start pa-4>
              <v-flex xs10>
                <v-layout wrap justify-start>
                  <v-flex xs12 class="title-1">
                    {{ $t(item.title) }}
                  </v-flex>
                  <v-flex xs12 pt-2 class="subtitle-2">
                    {{ $t(item.description) }}
                  </v-flex>
                </v-layout>
              </v-flex>
              <v-flex xs2 text-right>
                <AppButton custom-class="pa-5">
                  <template #content>
                    {{ $t("buttons.download") }}
                  </template>
                </AppButton>
              </v-flex>
            </v-layout>
          </v-card>
        </v-flex>
      </v-layout>
    </v-flex>
  </v-layout>
</template>

<script>
export default {
  name: "Reports",
  data: () => ({
    reports: [
      {
        title: "pages.report.inactivity",
        description: "pages.report.inactivity_desc",
      },
      {
        title: "pages.report.overtime",
        description: "pages.report.overtime_desc",
      },
      {
        title: "pages.report.archived",
        description: "pages.report.archived_desc",
      },
      {
        title: "pages.report.pooler_response",
        description: "pages.report.pooler_response_desc",
      },
    ],
  }),
  mounted() {
    const breadCrumbs = [
      {
        name: this.$route.name,
        text: "pages.report.title",
      },
    ];
    this.$store.commit("app/setBreadCrumbs", breadCrumbs);
  },
};
</script>

<style scoped></style>
